
import { defineComponent } from "vue";

export default defineComponent({
  name: "Root",
  computed: {
    experiments: function (): Array<any> {
      return this.$router
        .getRoutes()
        .filter((route) => route.meta.hidden !== true)
        .filter((route) => route.path !== "/");
    },
  },
});
